import React from "react";
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box } from "@mui/system";

const PhotoPreviewer = (props) => {

    const getPreviewCols = () => {
        if (window.innerWidth > 760) {
            return props.images.length;
        }
        return 3;
    }

  return (
    <div>
      <Box sx={{ overflowY: "scroll" }}>
        <ImageList
          cols={getPreviewCols()}
          rowHeight={200}
          rowWidth={150}
          variant="quilted"
          gap={2}
          scrollDirection="horizontal"
          justifyContent="center"
          itemLayout="horizontal"
        >
          {props.images.map((image) => (
            <ImageListItem key={image.name}>
              <img
                src={URL.createObjectURL(image)}
                alt={image.name}
                loading="eager"
              />
              <ImageListItemBar
                position="top"
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                    onClick={() => {
                        props.handleRemoveImage(image)
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </div>
  );
};

export default PhotoPreviewer;
