import { StoreConstants } from "../storeConstants";

export const setDefaultTimedError = (error) => {
    return (dispatch) => {
    dispatch(setTimedError(error));
    }
}

export const setTimedError = (error) => {
    return (dispatch) => {
    dispatch({
        type: StoreConstants.GENERAL_ERROR,
        payload: error,
      });
    }
}

export const clearError = () => {
  return (dispatch) => {
    dispatch({
      type: StoreConstants.CLEAR_ERROR
    });
  }
}