import { StoreConstants } from "../storeConstants";

const initialState = {
  isError: false,
  errorMessage: null,
};

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case StoreConstants.GENERAL_ERROR:
      return {
        isError: true,
        errorMessage: action.payload.message ?? "An unknown error occured",
      };
    case StoreConstants.CLEAR_ERROR:
      return {
        isError: false,
        errorMessage: null,
      };
    default:
      return state;
  }
};

export default errorReducer;
