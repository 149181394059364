import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';

export default function ResetDialog({open, setOpen, onConfirm}) {

  const handleConfirm = () => {
    setOpen(false);
    onConfirm();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you wish to reset this user's login? This cannot be undone."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Reset the user's login details and send them a new temporary password.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
  );
}
