import { StoreConstants } from "../storeConstants";

const initialState = {
  message: null,
  subject: null,
  filter: "all",
};

const communicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case StoreConstants.SET_EMAIL_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case StoreConstants.SET_EMAIL_SUBJECT:
      return {
        ...state,
        subject: action.payload,
      };
    case StoreConstants.SET_EMAIL_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    case StoreConstants.SEND_EMAIL_SUCCESS:
        return {
            message: null,
            subject: null,
            filter: "all"
        }
    default:
      return state;
  }
};

export default communicationReducer;
