import { useTheme } from "@emotion/react";
import { Box, Divider, Grid, Link, useMediaQuery } from "@mui/material";

const Venues = () => {

  const getCustomDivider = () => {
    return (<Divider sx={{ borderBottomWidth: 2, borderBottomColor: "#FF7F50" }} style={{ margin: `15px 40%`, width: `20%` }} />);
  }
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      justify="center"
      sx={{
        width: "95%",
        marginLeft: "2vw",
        marginRight: "2vw",
      }}
      overflow="hidden"
    >
      <h2>Waterberg Game Lodge</h2>
      <Link
                href={"https://www.google.com/maps?saddr=My+Location&daddr=Waterberg+Game+Lodge"}
                target="_blank"
                rel="noopener noreferrer"
                color={"#FF7F50"}
              >
      <h3>D180, Alma Road, Bela Bela</h3></Link>
      <Grid container>
        <Grid item xs={12} md={6} sx={{ padding: "2vw" }}>
          <img
            src={
              "./images/venue/venue1.jpg"
            }
            alt={"Waterberg Game Lodge"}
            style={{ width: "100%", height: "400px" }}
            loading="eager"
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ padding: "2vw" }}>
          <img
            src="./images/venue/venue2.jpg"
            alt={"Waterberg Game Lodge"}
            style={{ width: "100%", height: "400px" }}
            loading="eager"
          />
        </Grid>
      </Grid>
      <p style={{ margin: "20px 0 20px 0" }}>
        <strong>An authentically South African Bushveld Experience</strong>
      </p>
      <p style={{ margin: 0 }}>
        Waterberg Game Lodge is situated deep in the foothills of South Africa’s Waterberg Biosphere Reserve.
      </p>
      <p style={{ margin: 0 }}>
        The 2000 hectare malaria-free game reserve abounds with a wide variety of antelope, buffalo, leopard, brown hyena, giraffe, and hippo making it especially attractive to birders and wildlife admirers.
      </p>
      <p style={{ margin: 0 }}>
        The rustic accommodation overlooks the dam housing local hippos, as well as the beautiful Waterberg mountains.
      </p>

      <Grid container>
        <Grid item xs={0} md={3} />
        {!md && <Grid item xs={0} md={3} sx={{ padding: "2vw" }}>
          <img
            src="./images/venue/venue3.jpg"
            alt={"Waterberg Game Lodge"}
            style={{ width: "300px", height: "300px" }}
            loading="eager"
          />
        </Grid>}
        <Grid item xs={12} md={3} sx={{ padding: "2vw" }}>
          <img
            src="./images/venue/venue4.jpg"
            alt={"Waterberg Game Lodge"}
            style={{ width: "300px", height: "300px" }}
            loading="eager"
          />
        </Grid>
        <Grid item xs={0} md={3} />
      </Grid>
      {getCustomDivider()}
      <p style={{ margin: 0 }}>
        We have been lucky enough to have a venue that can accomodate almost all of our guests on the night of the wedding.</p>
      <p style={{ margin: 0 }}>
        If you would like to join us and stay on the reserve that evening, please let us know in your RSVP.
      </p>
      <p style={{ margin: 0 }}>
        For more details on the venue, visit their website <a href="https://waterberglodge.com/">here.</a>
      </p>
    </Box>
  );
};

export default Venues;
