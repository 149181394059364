import { Accordion, AccordionDetails, AccordionSummary, Grid, Switch, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeRsvp, getCurrentUser } from "../../Store/actions/apiActions";
import { Box, useMediaQuery } from "@mui/system";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { isAfterRSVPDate, isIOrWe } from "../../Utils/InviteeUtil";
import { useTheme } from "@emotion/react";
import CustomDivider from "../../Components/Common/CustomDivider";

const KitchenTea = () => {
  const { currentInvitee } = useSelector((state) => state.apiReducer);
  const dispatch = useDispatch();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));

  useEffect(() => {
    // Function to fetch data from the API
    async function fetchData() {
      dispatch(getCurrentUser());
    }
    // Call the fetchData function when the component mounts
    fetchData();
  }, [dispatch]);
  
  const handleChangeGardenTeaResponse = (event) => {
    dispatch(changeRsvp({
      ...currentInvitee.rsvp,
      gardenTea: !currentInvitee.rsvp.gardenTea,
    }));
  };
  
  const MyCustomDivider = () => {
    return <CustomDivider style={{ margin: `15px 40%`, width: `20%` }} />
  }

  return (
    <Grid container overflow="hidden">
      <Grid item xs={0} md={3} />
      <Grid item xs={12} md={6}>
        <Box
          style={{
            position: "relative",
            backgroundImage: `url('/WeddingLogoWatermark.svg')`, // Replace with the actual path to your watermark image
            backgroundPosition: "center", // Adjust as needed
            backgroundRepeat: "no-repeat", // Adjust as needed
            backgroundColor: "rgba(255, 255, 255, 0.2)",
          }}
        >
          
          <br />
          <Typography variant="h3" sx={{ mt: 1 }}>
            Dearest {currentInvitee?.name}
          </Typography>
          <MyCustomDivider />
          <Typography variant="h4">
            Thank you for your interest in the kitchen tea! ☕
          </Typography>
          <Typography variant="h4">
            We're in the midst of finalizing all the delightful details and will share more information with you soon.
          </Typography>
          <Typography variant="h4">
            Save the date: 15 March 2025 🗓️
          </Typography>
          <Typography variant="h4">
            Time and place to be confirmed.
          </Typography>
          <MyCustomDivider />
          <Typography variant="h4">
            All the best,
          </Typography>
          <Typography variant="h4">
            Your Maid and Matron of Honor,
          </Typography>
          <Typography variant="h3">
            Ang & Lizzie
          </Typography>
        </Box>
        {/* <Grid container>
              <Grid item xs={11}>
                <p>
                  {currentInvitee?.rsvp?.gardenTea
                    ? (!xs ? `${isIOrWe(currentInvitee)} will be attending the garden tea.` : "Attending the tea")
                    : (!xs ? `${isIOrWe(currentInvitee)} will not be attending the garden tea.` : "Not attending the tea")}
                </p>
              </Grid>
              <Grid item xs={1}>
                <Switch
                  checked={currentInvitee?.rsvp?.gardenTea}
                  color="warning"
                  onChange={handleChangeGardenTeaResponse}
                  disabled={isAfterRSVPDate()}
                />
              </Grid>
            </Grid>
      </Grid>
      <Grid item xs={12} md={3} sx={{ paddingX: "10px"}}>
      <p>
          <Accordion sx={{ width: "100%", margin: "0" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <h3>Agenda:</h3>
            </AccordionSummary>
            <AccordionDetails sx={{ height: "80vh"}}>

            </AccordionDetails>
          </Accordion>
        </p> */}
        </Grid> 
    </Grid>
  );
};

export default KitchenTea;
