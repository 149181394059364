import { Grid, Box, IconButton, Tooltip, useMediaQuery } from "@mui/material";
import { TextField, InputAdornment } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSpotifyAuthToken,
  searchSpotify,
} from "../../Store/actions/spotifyActions";
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from "@mui/icons-material/Search";
import { changeRsvp } from "../../Store/actions/apiActions";
import { StoreConstants } from "../../Store/storeConstants";

const SpotifySong = ({ disabled }) => {
  const md = useMediaQuery("(min-width: 960px)");
  const dispatch = useDispatch();

  const { song, searchUrl } = useSelector((state) => state.spotifyReducer);
  const { rsvpInvitee } = useSelector((state) => state.apiReducer);

  const handleSearchUrlChange = (event) => {
    dispatch({
      type: "CHANGE_SEARCH_URL",
      payload: event.target.value
    });
  };

  const handleSearch = async () => {
    if (searchUrl === "") {
      dispatch({
        type: StoreConstants.GET_SONG_SUCCESS,
        payload: {
          title: "",
          artists: "",
          imgUrl: "",
          uri: "",
        },
      });
    } else {
      dispatch(searchSpotify(searchUrl));
    }
  };

  const handleAdd = () => {
    dispatch(
      changeRsvp({
        ...rsvpInvitee.rsvp,
        songRequest: {
          title: song.title,
          artist: song.artists,
          url: searchUrl,
        },
      }, rsvpInvitee)
    );
  };

  useEffect(() => {
    // Function to fetch data from the API
    async function fetchData() {
      dispatch(getSpotifyAuthToken());
      dispatch({
        type: "CHANGE_SEARCH_URL",
        payload: rsvpInvitee?.rsvp?.songRequest?.url
      });
      if (rsvpInvitee?.rsvp?.songRequest?.url.length > 0) {
        dispatch(searchSpotify());
      }
      else {
        dispatch({
          type: StoreConstants.CLEAR_SONG
        })
      }
    }
    // Call the fetchData function when the component mounts
    fetchData();
  }, [dispatch, rsvpInvitee]);

  const imageSizeXS = "60vw";
  const imageSizeMD = 300;

  return (
    <Box>
      <Grid container>
        <Grid item xs={song?.title === "" ? 0 : 12} md={song?.title === "" ? 0 : 4} hidden={song?.title === ""}>
          <Box
            sx={{
              width: md ? imageSizeMD : imageSizeXS,
              height: md ? imageSizeMD : imageSizeXS,
              paddingLeft: md ? "50px" : "7.5vw",
            }}
          >
            <img
              src={song?.imgUrl}
              style={{
                maxWidth: md ? imageSizeMD : imageSizeXS,
                maxHeight: md ? imageSizeMD : imageSizeXS,
              }}
              alt={song?.title}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={song?.title === "" ? 12 : 8}>
          <h3>{song?.title === "" ? "Request A song below" : song.title}</h3>
          <h4>{song?.artists === "" ? "Search using a spotify URL. Just Share the link and paste it here!" : song.artists}</h4>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <TextField
              sx={{
                width: "100%",
                input: { color: '#1A0062' } 
              }}
              value={searchUrl}
              onChange={handleSearchUrlChange}
              variant="outlined"
              fullWidth
              disabled={disabled}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title={"Search Spotify"} disabled={disabled}>
                      <IconButton
                        onClick={() => {
                          handleSearch();
                        }}
                        edge="end"
                        disabled={disabled}
                      >
                        <SearchIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"Add To Playlist"} disabled={disabled}>
                      <IconButton
                        disabled={disabled}
                        onClick={() => {
                          handleAdd();
                        }}
                      >
                        <SaveIcon
                          sx={{
                            width: 40,
                            height: 40,
                            color: !disabled ? "#FF7F50" : "default",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SpotifySong;
