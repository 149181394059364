import { Divider, Grid } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/system"
import PartyGoer from "../../Components/WeddingParty/PartyGoer";

const WeddingParty = () => {

    const getCustomDivider = () => {
        return (<Divider sx={{ borderBottomWidth: 2, borderBottomColor: "#FF7F50" }} style={{ margin: `15px 25%`, width: `50%` }} />);
    }

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.only("xs"));
    const sm = useMediaQuery(theme.breakpoints.only("sm"));
    const groomsmen = {
        bev: <PartyGoer name={`Bevan`} upperTitle={<h3 style={{ margin: 0 }}>Farrell{sm && <br/>}</h3>} lowerTitle={<h4 style={{ margin: 0 }}>Co-Best Man</h4>} img={"bev.jpeg"} quote={`The man that says his wife can't take a joke forgets that she took him.`} />,
        liam: <PartyGoer name={`Liam`} upperTitle={<h3 style={{ margin: "0px 10px" }}>Banks{sm && <br/>}</h3>} lowerTitle={<h4 style={{ margin: 0 }}>Co-Best Man</h4>} img={"liam.jpeg"} quote={`As a married man I can say, there are three looks your wife gives you; before, during and after you've done something wrong.`} />,
        joe: <PartyGoer name={`Joseph`} upperTitle={<h3 style={{ margin: "0px 10px" }}>Abawat</h3>} img={"joe.jpeg"} quote={`Love doesn't make the world go round. Love is what makes the ride worthwhile.`} />,
        dino: <PartyGoer name={"Dino"} upperTitle={<h3 style={{ margin: "0px 10px" }}>Anastasopoulos</h3>} img={"dino.jpeg"} quote={`If at first you don't succeed, try doing it the way your wife told you.`} />,
        steve: <PartyGoer name={'Steve'} upperTitle={<h3 style={{ margin: 0 }}>Meintjes</h3>} img={"steve.jpeg"} quote={`Marriage is like a road trip: unexpected detours, bickering and both wanting to take the wheel. But with the right copilot, even traffic is an adventure. So buckle up, laugh, and enjoy the ride together.`} />,
        andrea: <PartyGoer name={"Andrea"} upperTitle={<h3 style={{ margin: "0px 10px" }}>De Lorenzo</h3>} img={"andrea.jpeg"} quote={"Life is a journey and there is always a beautiful sunset at the end of the day."} />,
        matt: <PartyGoer name={`Matthew`} upperTitle={<h3 style={{ margin: "0px 10px" }}>Adair</h3>} img={"matt.jpeg"} quote={`Relationships are like a walk in the park. \n\n Jurassic Park.`} />,
        izak: <PartyGoer name={"Izak"} upperTitle={<h3 style={{margin:0}}>Olivier</h3>} img={"izak.jpeg"} lowerTitle={<h4 style={{margin:0}}>Protector of the Rings</h4>}/>
    }

    const bridesmaids = {
        ang: <PartyGoer name={`Angela`} upperTitle={<h3 style={{ margin: 0 }}>Olivier{sm && <br/>}</h3>} lowerTitle={<h4 style={{ margin: 0 }}>Maid of Honor</h4>} img={"ang.jpeg"} quote={`I'd write love poems to the parts of yourself you can't stand.${sm ? "" : "I'd stand in the shadows of your heart and tell you I'm not afraid of your dark."}`} />,
        liz: <PartyGoer name={"Elizabeth"} upperTitle={<h3 style={{ margin: 0 }}>Lemmer-Banks</h3>} lowerTitle={<h4 style={{ margin: 0 }}>Matron of Honor</h4>} img={"liz.jpeg"} quote={`"You know you're in love when you can't fall asleep because reality is finally better than your dreams."\n - Dr. Seuss`} />,
        cat: <PartyGoer name={"Catherine "} upperTitle={<h3 style={{ margin: 0 }}>Campbell</h3>} img={"cat.jpeg"} quote={`"Any day spent with you is my favorite day. So, today is my new favorite day." - A.A.Milne`} />,
        sabs: <PartyGoer name={"Sabrina"} upperTitle={<h3 style={{ margin: 0 }}>Benvenuti</h3>} img={"sabs.jpeg"} quote={"Passing the baton of \"Marco's favourite person to annoy\""} />,
        chanelle: <PartyGoer name={"Chanelle"} upperTitle={<h3 style={{ margin: 0 }}> Van&nbsp;Wyngaard</h3>} img={"chanelle.jpeg"} quote={"You don't marry the prettiest girl at the party. You marry the one whose yelling at the beer pong table because she's losing. That girl's going to raise winners."} />,
        faye: <PartyGoer name={"Faye"} upperTitle={<h3 style={{ margin: 0 }}>Falconer&nbsp;Pincus</h3>} img={"faye.jpeg"} quote={"Love is a quiet, reassuring, relaxing, harmonious hum of a thing; you can easily forget it's there, even though it's palms are outstretched beneath you, in case you fall"} />,
        anelka: <PartyGoer name={"Anelka"} upperTitle={<h3 style={{ margin: 0 }}>Poultney</h3>} img={"anelka.jpeg"} quote={`Cheers! To two imperfect pieces that fit perfectly together.`} />,
        bella: <PartyGoer name={"Izabella"} upperTitle={<h3 style={{ margin: 0 }}>Olivier</h3>} img={"bella.jpeg"} lowerTitle={<h4 style={{margin:0}}>Princess of the Petals</h4>}/>
    }

    const wrapPartyGoer = (size, partyGoer) => {
        return (
            <Grid item xs={size}>
                {partyGoer}
            </Grid>
        )
    }
    const wrapDivider = (size) => {
        return (
            <Grid item xs={size}>
                {getCustomDivider()}
            </Grid>
        )
    }

    const getGroom = (size) => { return wrapPartyGoer(size, <PartyGoer name={"Marco Benvenuti"} largeImg={true} upperTitle={<h4 style={{ margin: 0 }}>The Groom</h4>} img={"marco.jpeg"} quote={`Every love story is beautiful, but ours is my favorite.`} />); }
    const getBride = (size) => { return wrapPartyGoer(size, <PartyGoer name={"Gabriella Olivier"} largeImg={true} upperTitle={<h4 style={{ margin: 0 }}>The Bride</h4>} img={"gaby.jpg"} quote={`"My atoms love your atoms, it's chemistry" ${sm ? "" : "\n"} - Atticus`} />); }
    const getHonorSoc = () => {
        if (xs || sm) return getHonorSocXs();
        return (
            <Grid container>
                {wrapPartyGoer(3, groomsmen.bev)}
                {wrapPartyGoer(3, groomsmen.liam)}
                {wrapPartyGoer(3, bridesmaids.liz)}
                {wrapPartyGoer(3, bridesmaids.ang)}
                {wrapDivider(3)}
                {wrapDivider(3)}
                {wrapDivider(3)}
                {wrapDivider(3)}
            </Grid>);
    }
    const getHonorSocXs = () => {
        return (
            <Grid container>
                {wrapPartyGoer(6, groomsmen.bev)}
                {wrapPartyGoer(6, bridesmaids.ang)}
                {wrapDivider(6)}
                {wrapDivider(6)}
                {wrapPartyGoer(6, groomsmen.liam)}
                {wrapPartyGoer(6, bridesmaids.liz)}
                {wrapDivider(6)}
                {wrapDivider(6)}
            </Grid>);
    }
    const getWeddingPartyXs = () => {
        return (
            <Grid container>
                {wrapPartyGoer(6, groomsmen.joe)}
                {wrapPartyGoer(6, bridesmaids.cat)}
                {wrapDivider(6)}
                {wrapDivider(6)}
                {wrapPartyGoer(6, groomsmen.dino)}
                {wrapPartyGoer(6, bridesmaids.sabs)}
                {wrapDivider(6)}
                {wrapDivider(6)}
                {wrapPartyGoer(6, groomsmen.steve)}
                {wrapPartyGoer(6, bridesmaids.chanelle)}
                {wrapDivider(6)}
                {wrapDivider(6)}
                {wrapPartyGoer(6, groomsmen.andrea)}
                {wrapPartyGoer(6, bridesmaids.anelka)}
                {wrapDivider(6)}
                {wrapDivider(6)}
                {wrapPartyGoer(6, groomsmen.matt)}
                {wrapPartyGoer(6, bridesmaids.faye)}
                {wrapDivider(6)}
                {wrapDivider(6)}
                {wrapPartyGoer(6, groomsmen.izak)}
                {wrapPartyGoer(6, bridesmaids.bella)}
                {wrapDivider(6)}
                {wrapDivider(6)}
            </Grid>);
    }
    const getWeddingParty = () => {
        if (xs || sm) return getWeddingPartyXs();
        return (
            <Grid container>
                {wrapPartyGoer(3, groomsmen.joe)}
                {wrapPartyGoer(3, groomsmen.andrea)}
                {wrapPartyGoer(3, bridesmaids.cat)}
                {wrapPartyGoer(3, bridesmaids.sabs)}
                {wrapDivider(3)}
                {wrapDivider(3)}
                {wrapDivider(3)}
                {wrapDivider(3)}
                {wrapPartyGoer(3, groomsmen.matt)}
                {wrapPartyGoer(3, groomsmen.steve)}
                {wrapPartyGoer(3, bridesmaids.chanelle)}
                {wrapPartyGoer(3, bridesmaids.faye)}
                {wrapDivider(3)}
                {wrapDivider(3)}
                {wrapDivider(3)}
                {wrapDivider(3)}
                {wrapPartyGoer(3, groomsmen.izak)}
                {wrapPartyGoer(3, groomsmen.dino)}
                {wrapPartyGoer(3, bridesmaids.anelka)}
                {wrapPartyGoer(3, bridesmaids.bella)}
                {wrapDivider(3)}
                {wrapDivider(3)}
                {wrapDivider(3)}
                {wrapDivider(3)}
            </Grid>);
    }
    if (xs) {
        return (
            <Grid container margin={0} padding={0}>
                <Grid item xs={12}>
                    <h2>Our Wedding Party</h2>
                </Grid>
                {getGroom(12)}
                {wrapDivider(12)}
                {getBride(12)}
                {wrapDivider(12)}
                {getHonorSoc()}
                <Grid item xs={6}>
                    <h3>The Groomsmen</h3>
                </Grid>
                <Grid item xs={6}>
                    <h3>The Bridemaids</h3>
                </Grid>
                {getWeddingParty()}
            </Grid>);
    }
    if (sm) {
        return (
            <Grid container margin={0} padding={0}>
                <Grid item xs={12}>
                    <h2>Our Wedding Party</h2>
                </Grid>
                {getGroom(6)}
                {getBride(6)}
                {wrapDivider(6)}
                {wrapDivider(6)}
                {getHonorSoc()}
                <Grid item xs={6}>
                    <h3>The Groomsmen</h3>
                </Grid>
                <Grid item xs={6}>
                    <h3>The Bridemaids</h3>
                </Grid>
                {getWeddingParty()}
            </Grid>);
    }

    return (
        <Grid container margin={0} padding={0}>
            <Grid item xs={12}>
                <h2>Our Wedding Party</h2>
            </Grid>
            {getGroom(6)}
            {getBride(6)}
            {wrapDivider(6)}
            {wrapDivider(6)}
            {getHonorSoc()}
            <Grid item xs={6}>
                <h3>The Groomsmen</h3>
            </Grid>
            <Grid item xs={6}>
                <h3>The Bridemaids</h3>
            </Grid>
            {getWeddingParty()}
        </Grid>);
}

export default WeddingParty;