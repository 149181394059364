export const tabs_ = [
    {
      label: "The Wedding",
      value: 10,
      children: [
        {
          label: "The Invitation",
          value: 10,
          key: "10_inv",
        },
        {
          label: "The Wedding Party",
          value: 11,
          key: "11_par",
        }, {
          label: "The Venue",
          value: 12,
          key: "12_ven",
        },
        {
          label: "FAQs",
          value: 13,
          key: "13_faq"
        }
      ]
    },
    {
      label: "Other Celebrations",
      value: 20,
      children: [{
        label: "Rehearsal Dinner",
        value: 20,
        key: "20_din",
      },
      {
        label: "The Bachelors Party",
        value: 21,
        key: "21_bch",
      },
      {
        label: "The Bachelorette Party",
        value: 22,
        key: "22_blr",
      },
      {
        label: "The Kitchen Tea",
        value: 23,
        key: "23_knt",
      }, {
        label: "Post-Wedding Breakfast",
        value: 24,
        key: "24_pwb",
      }]
    },
    {
      label: "RSVP Here",
      value: 30,
      key: "30_rsvp",
    },
    {
      label: "Gift Registry",
      value: 40,
      key: "40_gft",
    },
    {
      label: "The Photos",
      value: 50,
      key: "50_pic",
    },
    {
      label: "The Admin",
      value: 60,
      children: [
        {
          label: "Guest Management",
          value: 60,
          key: "60_adm",
        }, {
          label: "Email Guests",
          value: 61,
          key: "61_emg",
        }, {
            label: "Feature Toggles",
            value: 62,
            key: "62_ftg",
          }]
    }
  ];