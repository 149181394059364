import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useSelector } from "react-redux";

const SpotifyWidget = () => {
  const { session } = useSelector((state) => state.userReducer);
  const [isExpanded, setIsExpanded] = useState(true);

  
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const md = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const checkState = () => {
      setIsExpanded(session !== null && !md);
    };
    checkState();
  }, [session, setIsExpanded, md]);

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        right: 0,
        zIndex: 10,
      }}
    >
      <Accordion
        expanded={isExpanded}
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          width: xs ? "100vw" : "352px"
        }}
      >
        <AccordionSummary
          sx={{
            margin: 0,
            padding: "0 0 0 10px",
            maxHeight: md || !isExpanded ? "80px" : "80px"
          }}
          expandIcon={
            <IconButton onClick={handleToggleExpand}>
              {isExpanded ? (
                <ExpandLessIcon />
              ) : (
                <svg
                  style={{ color: "green" }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M17.9 10.9C14.7 9 9.35 8.8 6.3 9.75c-.5.15-1-.15-1.15-.6c-.15-.5.15-1 .6-1.15c3.55-1.05 9.4-.85 13.1 1.35c.45.25.6.85.35 1.3c-.25.35-.85.5-1.3.25m-.1 2.8c-.25.35-.7.5-1.05.25c-2.7-1.65-6.8-2.15-9.95-1.15c-.4.1-.85-.1-.95-.5c-.1-.4.1-.85.5-.95c3.65-1.1 8.15-.55 11.25 1.35c.3.15.45.65.2 1m-1.2 2.75c-.2.3-.55.4-.85.2c-2.35-1.45-5.3-1.75-8.8-.95c-.35.1-.65-.15-.75-.45c-.1-.35.15-.65.45-.75c3.8-.85 7.1-.5 9.7 1.1c.35.15.4.55.25.85M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2Z"
                  />
                </svg>
              )}
            </IconButton>
          }
        >
           {!isExpanded ? <h3>🎵 Wanna&nbsp;Party?</h3> : <h3>🎵 Party&nbsp;Time</h3>}
        </AccordionSummary>
        <AccordionDetails
          sx={{
            margin: 0,
            padding: "0px",
            maxHeight: xs ? "150" : "360"
          }}
        >
          <iframe
            style={{ borderRadius: "12px"}}
            src="https://open.spotify.com/embed/playlist/6qyEtFFyWrShqCGCZA882i"
            width="99%"
            height={md ? "80" : "352"} 
            frameBorder="0"
            allowFullScreen={true}
            loading="eager"
            autoPlay={true}
            title="SpotifyWidget"
          ></iframe>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default SpotifyWidget;
