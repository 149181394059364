import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, IconButton, Typography } from "@mui/material"
import { useState } from "react";


const FAQs_ = [
    { "question": "When is the wedding?", "answer": "The wedding will take place at 4pm on 22nd March 2025. Please arrive 30 minutes early and be seated by 3:50pm." },
    { "question": "Where is the wedding taking place?", "answer": "The wedding will be held at Waterberg Game Lodge. More details can be found on the Venues page of our website." },
    { "question": "How do I RSVP?", "answer": "Please RSVP on our website, on the RSVP page" },
    { "question": "Can I bring a plus-one or children?", "answer": "Due to space limitations, 'plus ones' and children are only allowed if they have been specifically included in your invitation." },
    { "question": "What should I wear?", "answer": "The dress code is semi-formal. No jeans, please." },
    { "question": "What is the wedding itinerary?", "answer": "The itinerary will be confirmed closer to the date." },
    { "question": "Is there parking available?", "answer": "Yes, parking is available on the premises." },
    { "question": "Do you have a gift registry?", "answer": "Our gift registry will be available shortly via the website." },
    { "question": "Are there any dietary restrictions or special menu options?", "answer": "Please let us know any dietary restrictions when you RSVP." },
    { "question": "Will there be accommodations for out-of-town guests?", "answer": "The venue can accommodate most guests. Additional nearby accommodations will be provided on the website soon." },
    { "question": "Is there a shuttle service from the hotel to the venue?", "answer": "No shuttle service is needed as the accommodations and venue are very close together." },
    { "question": "What time should we arrive?", "answer": "Please arrive 30 minutes before the ceremony starts." },
    { "question": "Can I bring a gift to the wedding?", "answer": "Please bear with us, we will provide more details shortly via this website." },
    { "question": "What is the weather typically like at this time of year?", "answer": "The weather is typically hot, around 28-30°C." },
    { "question": "What is the latest time we can RSVP?", "answer": "Please RSVP by 15th December 23:59. Accommodation payments are due by 15th January 23:59." }
  ];

const FAQ = ({ question, answer }) => {
    const [expanded, setExpanded] = useState(false);
    return (
        <Accordion
            expanded={expanded}
            defaultExpanded={false}
            sx={{
                backgroundColor: "rgba(0, 0, 0, 0.05)",
                border: "1px solid #FF7F5060"
            }}
            onChange={() => setExpanded(!expanded)}
        >
            <AccordionSummary style={{ height: "120px" }}>
                <Grid container>
                    <Grid item xs={11}>
                        <Typography variant="h4" style={{ color: "#1A0062" }}>{question}</Typography>
                    </Grid>
                    <Grid xs={1}>
                        <Typography variant="h4" style={{ color: "#1A0062" }}>
                            <IconButton onClick={() => setExpanded(!expanded)} style={{ fontSize: "" }}>
                                {expanded ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                        </Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Divider sx={{ borderBottomWidth: 2, borderBottomColor: "#FF7F50" }} style={{ margin: `15px 40%`, width: `20%` }} />
                <Typography variant="h5">{answer}</Typography>
            </AccordionDetails>
        </Accordion>);
}

const FAQs = () => {
    return (<Grid style={{ width: "80%", marginInlineStart: "10%" }} spacing={4}>
        {FAQs_.map(faq => <Grid item xs={12} style={{ padding: "10px" }}>
            <FAQ question={faq.question} answer={faq.answer} />
        </Grid>)}
    </Grid>
    )
}

export default FAQs;