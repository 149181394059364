import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeRsvp, getCurrentUser } from "../../Store/actions/apiActions";
import { Box, useMediaQuery } from "@mui/system";
import { useTheme } from "@emotion/react";
import { isIOrWe } from "../../Utils/InviteeUtil";
import CustomDivider from "../../Components/Common/CustomDivider";

const Bachelors = () => {
  const { currentInvitee } = useSelector((state) => state.apiReducer);
  const dispatch = useDispatch();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));


  const handleChangeBachelorsResponse = (event) => {
    dispatch(changeRsvp({
      ...currentInvitee.rsvp,
      bachelors: !currentInvitee.rsvp.bachelors,
    }));
  };

  useEffect(() => {
    // Function to fetch data from the API
    async function fetchData() {
      dispatch(getCurrentUser());
    }
    // Call the fetchData function when the component mounts
    fetchData();
  }, [dispatch]);

  const MyCustomDivider = () => {
    return <CustomDivider style={{ margin: `15px 40%`, width: `20%` }} />
  }

  return (
    <Grid container overflow="hidden">
      <Grid item xs={0} md={3} />
      <Grid item xs={12} md={6}>
        <Box>
          <br />
          <Typography variant="h3" sx={{ mt: 1 }}>
            Hey {currentInvitee?.name}
          </Typography>
          <MyCustomDivider />
          <Typography variant="h4">
            Thanks for your enthusiasm about the bachelor party! 🎉
          </Typography>
          <Typography variant="h4">
            We're finalizing all the epic plans and will get back to you with the exciting details soon.
          </Typography>
          <Typography variant="h4">
            Save the date: 15 March 2025 🗓️
          </Typography>
          <Typography variant="h4">
            Time and place to be confirmed.
          </Typography>
          <MyCustomDivider />
          <Typography variant="h4">
            Best regards,
          </Typography>
          <Typography variant="h4">
            The Best Men
          </Typography>
          <Typography variant="h3">
            Bevan & Liam
          </Typography>
          {/* <Accordion
              expanded={true}
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.05)",
              }}
            >
              <AccordionSummary sx={{ pointerEvents: "none" }}>
                <p>Will you be joining us for the bachelors party?</p>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid item xs={10} md={11}>
                    <p>
                      {currentInvitee?.rsvp?.bachelors
                        ? !xs
                          ? `Yes, ${isIOrWe(currentInvitee)} will be attending the bachelors party.`
                          : "Yes, Attending the bachelors"
                        : !xs
                          ? `No, ${isIOrWe(currentInvitee)} will not be attending the bachelors party.`
                          : "No, Not attending the bachelors"}
                    </p>
                  </Grid>
                  <Grid item xs={2} md={1}>
                    <Switch
                      checked={currentInvitee?.rsvp?.bachelors}
                      color="warning"
                      onChange={handleChangeBachelorsResponse}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion> */}
        </Box>
      </Grid>
      <Grid item xs={0} md={3} />
    </Grid>
  );
};

export default Bachelors;
