import React, { useState } from "react";
import { Box, FilledInput, FormControl, IconButton, InputAdornment, InputLabel } from "@mui/material";
import CustomButton from "../../Components/Buttons/Button";
import { useDispatch } from "react-redux";
import { changePassword } from "../../Store/actions/userActions";
import { StoreConstants } from "../../Store/storeConstants";
import LockIcon from '@mui/icons-material/Lock';
import { Visibility, VisibilityOff } from "@mui/icons-material";

const NewPasswordComponent = ({ xs }) => {
  const dispatch = useDispatch();
  var [password1, setPassword1] = useState("");
  var [password2, setPassword2] = useState("");

  const handlePasswordChange = async () => {
    if (password1 === password2) {
      dispatch(changePassword(password1));
    } else {
      dispatch({
        type: StoreConstants.GENERAL_ERROR,
        payload: {
          message: "Passwords do not match!",
        }
      });
    }
  };

  var [obfuscateNew, setObfuscateNew] = useState(true);
  const toggleObfuscateNew = () => {
    setObfuscateNew(!obfuscateNew);
  };
  var [obfuscateConfirm, setObfuscateConfirm] = useState(true);
  const toggleObfuscateConfirm = () => {
    setObfuscateConfirm(!obfuscateConfirm);
  };

  return (
    <Box>
      {xs ? <h1 style={{ margin: 0 }}>Change Password</h1> : <h1>Change Password</h1>}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
          <InputLabel htmlFor="filled-adornment-password">New Password</InputLabel>
          <FilledInput
            id="filled-adornment-password"
            type={!obfuscateNew ? 'text' : 'password'}
            value={password1}
            onChange={(e) => setPassword1(e.target.value)}
            sx={{ marginTop: "10px", fontSize: "24px" }}
            startAdornment={
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={toggleObfuscateNew}
                  edge="end"
                >
                  {!obfuscateNew ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
          <InputLabel htmlFor="filled-adornment-password">Confirm Password</InputLabel>
          <FilledInput
            id="filled-adornment-password"
            type={!obfuscateConfirm ? 'text' : 'password'}
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
            sx={{ marginTop: "10px", fontSize: "24px" }}
            startAdornment={
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={toggleObfuscateConfirm}
                  edge="end"
                >
                  {!obfuscateConfirm ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "right",
          alignItems: "right",
        }}
      >
        <CustomButton onClick={() => handlePasswordChange()} text="Confirm" />
      </Box>
    </Box>
  );
};

export default NewPasswordComponent;
