import React, { useState } from "react";
import { Box, FilledInput, FormControl, IconButton, InputAdornment, InputLabel } from "@mui/material";
import CustomButton from "../../Components/Buttons/Button";
import { useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import { StoreConstants } from "../../Store/storeConstants";
import { AccountCircle, Visibility, VisibilityOff } from "@mui/icons-material";
import LockIcon from '@mui/icons-material/Lock';

const ForgotPasswordComponent = ({ xs }) => {
  const dispatch = useDispatch();
  var [username, setUsername] = useState("");
  var [code, setCode] = useState("");
  var [sent, setSent] = useState(false);
  var [complete, setComplete] = useState(false);
  var [password1, setPassword1] = useState("");
  var [password2, setPassword2] = useState("");

  const handleForgotPasswordInit = async () => {
    await Auth.forgotPassword(username);
    setSent(true);
  };

  const handleForgotPasswordComplete = async () => {
    if (password1 === password2) {
      Auth.forgotPasswordSubmit(username, code, password1)
        .then((result) => {
          setComplete(true)
        }).catch((error) => {
          dispatch({
            type: StoreConstants.GENERAL_ERROR,
            payload: error,
          });
        });
    } else {
      dispatch({
        type: StoreConstants.GENERAL_ERROR,
        payload: {
          message: "Passwords do not match!",
        },
      });
    }
  };


  var [obfuscateNew, setObfuscateNew] = useState(true);
  const toggleObfuscateNew = () => {
    setObfuscateNew(!obfuscateNew);
  };
  var [obfuscateConfirm, setObfuscateConfirm] = useState(true);
  const toggleObfuscateConfirm = () => {
    setObfuscateConfirm(!obfuscateConfirm);
  };

  return (
    <Box>
      {xs ? <h1 style={{ margin: 0 }}>Reset Password</h1> : <h1>Reset Password</h1>}
      {complete ?
        <p>Complete. Go back to Login</p> :
        <>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FormControl sx={{
              m: 1, width: '25ch',
            }} variant="filled">
              <InputLabel htmlFor="filled-adornment-username">{sent ? "Code" : "Email"}</InputLabel>
              <FilledInput
                id="filled-adornment-username"
                type="text"
                value={sent ? code : username}
                onChange={(e) => sent ? setCode(e.target.value) : setUsername(e.target.value)}
                sx={{ marginTop: "10px", fontSize: "24px" }}
                startAdornment={
                  <InputAdornment position="start">
                    {sent ? <></> : <AccountCircle />}
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
          {sent && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
                <InputLabel htmlFor="filled-adornment-password">New Password</InputLabel>
                <FilledInput
                  id="filled-adornment-password"
                  type={!obfuscateNew ? 'text' : 'password'}
                  value={password1}
                  onChange={(e) => setPassword1(e.target.value)}
                  sx={{ marginTop: "10px", fontSize: "24px" }}
                  startAdornment={
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleObfuscateNew}
                        edge="end"
                      >
                        {!obfuscateNew ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
          )}
          {sent && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
                <InputLabel htmlFor="filled-adornment-password">Confirm Password</InputLabel>
                <FilledInput
                  id="filled-adornment-password"
                  type={!obfuscateConfirm ? 'text' : 'password'}
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                  sx={{ marginTop: "10px", fontSize: "24px" }}
                  startAdornment={
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleObfuscateConfirm}
                        edge="end"
                      >
                        {!obfuscateConfirm ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
          )}
          {sent && "Check your email for the verification code"}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "right",
              alignItems: "right",
            }}
          >
            <CustomButton onClick={() => {
              sent ? handleForgotPasswordComplete() : handleForgotPasswordInit()
            }} text={!sent ? "Send Email" : "Submit"} />
          </Box>
        </>}
    </Box>
  );
};

export default ForgotPasswordComponent;
