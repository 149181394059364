import { combineReducers } from "redux";
import userReducer from "./userReducer";
import apiReducer from "./apiReducer";
import photoReducer from "./photoReducer";
import errorReducer from "./errorReducer";
import spotifyReducer from "./spotifyReducer";
import communicationReducer from "./communicationReducer";

const rootReducer = combineReducers({
    userReducer,
    apiReducer,
    photoReducer,
    errorReducer,
    spotifyReducer,
    communicationReducer
});

export default rootReducer;