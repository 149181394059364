import axios from "axios";
import awsConfig from "../../config/awsConfig";
import { StoreConstants } from "../storeConstants";
import { getHeaders } from "../general";
import { setDefaultTimedError } from "./errorActions";

const communicationEndpoint = `${awsConfig.api_endpoint}/communication`;

export const sendEmail = () => {
  return (dispatch, getState) => {
    const { message, subject, filter } = getState().communicationReducer;

    axios.post(communicationEndpoint, {
        message: message,
        subject: subject,
        filter: filter
    }, {
      headers: getHeaders(getState)
    }).then((response) => {
      dispatch({
        type: StoreConstants.SEND_EMAIL_SUCCESS
      });
    })
    .catch((error) => {
      dispatch(setDefaultTimedError(error))
    });
  }
}