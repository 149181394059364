import { Grid, Typography } from "@mui/material";

export const CustomCountdown = ({ xs, days, hours, minutes, seconds }) => {
  // Mapping the date values to radius values
  const daysRadius = mapNumber(days, 0, 365, 0, 360);
  const hoursRadius = mapNumber(hours, 0, 24, 0, 360);
  const minutesRadius = mapNumber(minutes, 0, 60, 0, 360);
  return (
    <Grid container style={{width: "100%", padding: xs ? "0px 0px 0px 0px" : "0px 50px 0px 0px"}}>
      <Grid item xs={12}>
        {xs ? <></> : <Typography variant="h2">See You In...</Typography>}
      </Grid>
      <Grid container className="countdown-wrapper" justify="center">
        {/* {xs ? <Grid xs={1}/> : <></>} */}
        <Grid item xs={4} md={4} className="countdown-item">
          <SVGCircle xs={xs} radius={daysRadius} text={days} color="#FF7F50" unit="d"/>
        </Grid>
        <Grid item xs={4} md={4} className="countdown-item">
          <SVGCircle xs={xs} radius={hoursRadius} text={hours} color="#A4602F" unit="h" />
        </Grid>
        <Grid item xs={4} md={4} className="countdown-item">
          <SVGCircle xs={xs} radius={minutesRadius} text={minutes} color="#761D23" unit="m" />
        </Grid>
        {/* <Grid item xs={2} md={3} className="countdown-item">
          <SVGCircle xs={xs} radius={secondsRadius} text={seconds} color="#761D23" unit="s" />
        </Grid> */}
        {/* {xs ? <Grid xs={1}/> : <></>} */}
      </Grid>
    </Grid>
  );
};

const SVGCircle = ({ xs, radius, text, color, unit }) => (
  <svg className="countdown-svg" style={{ color: color, width: xs ? "70px" : "fit-content", height: xs ? "70px" : "fit-content"}}>
    <path
      fill="none"
      stroke={color}
      strokeWidth={xs ? "3" : "4"}
      d={describeArc(xs ? 32 : 55, xs ? 32 : 55, xs ? 30 : 50, 0, radius)}
    />
    <text x={xs ? 30 : 55} y={xs ? 29 : 50} textAnchor="middle" dy="0.3em" fill={color} fontWeight={800} style={{fontSize: xs ? "20px" : "32px", textShadow: "1px 1px 1px black" }}>
    {text}{unit}
  </text>
  </svg>
);

// From StackOverflow: https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle
function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

function describeArc(x, y, radius, startAngle, endAngle) {
  var start = polarToCartesian(x, y, radius, endAngle);
  var end = polarToCartesian(x, y, radius, startAngle);

  var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

  var d = [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(" ");

  return d;
}

// From StackOverflow: https://stackoverflow.com/questions/10756313/javascript-jquery-map-a-range-of-numbers-to-another-range-of-numbers
function mapNumber(number, in_min, in_max, out_min, out_max) {
  return (
    ((number - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
  );
}
