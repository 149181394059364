import { StoreConstants } from "../storeConstants";

const initialState = {
  invitees: [],
  currentInvitee: null,
  isLoading: false,
  rsvpInvitee: null,
};

const apiReducer = (state = initialState, action) => {
  switch (action.type) {
    case StoreConstants.LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case StoreConstants.GET_ALL_INVITEES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        invitees: action.payload.map((item) => {
          return {
            ...item,
            isEditMode: false,
          };
        }),
      };
    case StoreConstants.GET_INVITEE_SUCCESS:
      let currInvitees = {...state.invitees.filter(
        (invitee) => invitee.id !== action.payload.id
      )};
      return {
        ...state,
        invitees: [...currInvitees, action.payload].sort((a, b) => a.id - b.id),
        isLoading: false,
      };
    case StoreConstants.GET_CURRENT_INVITEE_SUCCESS:
      return {
        ...state,
        currentInvitee: action.payload,
        isLoading: false,
      };
    case StoreConstants.UPDATE_USER:
      const updatedInvitee = {...state.invitees.find(
        (invitee) => invitee.id === action.payload.id
      )};
      updatedInvitee[action.payload.key] = action.payload.value
      let prev = state.invitees.filter(
        (invitee) => invitee.id !== action.payload.id
      );
      return {
        ...state,
        isLoading: false,
        invitees: [
          ...prev,
            updatedInvitee
        ].sort((a, b) => a.id - b.id),
      };
    case StoreConstants.DELETE_INVITEE_SUCCESS:
        return {
            ...state,
            invitees: state.invitees.filter((i) => i.id !== action.payload).sort((a, b) => a.id - b.id)
        }
    case StoreConstants.ADD_EMPTY_INVITEE:
        return {
            ...state,
            invitees: [
                ...state.invitees,
                action.payload
            ]
        }
    case StoreConstants.GET_LINKED_INVITEE_SUCCESS:
      return {
        ...state,
        rsvpInvitee: action.payload
      };
    case StoreConstants.GET_LINKED_INVITEE_FAILED:
    case StoreConstants.DELETE_INVITEE_FAILED:
    case StoreConstants.GET_ALL_INVITEES_FAILED:
    case StoreConstants.GET_CURRENT_INVITEE_FAILED:
    case StoreConstants.SEND_EMAIL_SUCCESS:
    default:
      return {
        ...state,
        isLoading: false,
      };
  }
};

export default apiReducer;
