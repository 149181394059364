import { Box, Tab, Tabs, useMediaQuery, useTheme, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import "../App.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import TabAccordion from "./TabAccordion";

const TabComponent = ({ tabs, currentTabIndex, handleTabChange, featureToggles }) => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const indexAsString = currentTabIndex.toString();
  const mainTabIndex = indexAsString.substring(0, 1);

  const [mainExpanded, setMainExpanded] = useState(false);

  const getSelectedTabLabel = () => {
    const parent = tabs.find((t) => {
      if (t?.children?.length > 0) {
        return t.children.find((c) => c.value === currentTabIndex);
      }
      if (t.value === currentTabIndex) return t;
      return null;
    });

    if (parent?.children?.length > 0) {
      return parent?.children?.find((c) => c.value === currentTabIndex).label;
    }
    return parent?.label;
  }

  const isTabActiveForUser = (tabKey) => {
    return featureToggles[tabKey];
  }

  const showParentTab = (parent) => {
    if (parent?.children?.length > 0) {
      let show = false;
      for (const child of parent.children) {
        show |= isTabActiveForUser(child.key);
      }
      return show;
    }
    return isTabActiveForUser(parent.key);
  }

  const showChildTab = (child) => {
    return isTabActiveForUser(child.key);
  }

  const getEarliestActiveChild = (tab) => {
    if (!tab?.children?.length) return tab?.value;
    for (const child of tab?.children) {
      if (showChildTab(child)) {
        return child.value;
      }
    }
  }

  return (
    <Box sx={{ borderTop: 1, borderBottom: 1, borderColor: "divider" }}>
      {xs ? (
        <Accordion expanded={mainExpanded} style={{ color: "#1A0062", backgroundColor: "#fff6e6" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => setMainExpanded(!mainExpanded)}>
            <h4 style={{ margin: "0px", padding: "0px 20px" }} >{getSelectedTabLabel()}</h4>
          </AccordionSummary>
          <AccordionDetails>
            {tabs.map((tab) =>
            showParentTab(tab) ?
              <TabAccordion tab={tab} showChildTab={showChildTab} handleTabChange={(value) => {
                handleTabChange(value)
                setMainExpanded(false);
                window.scrollTo(0, 0);
              }} /> : <></>
            )}
          </AccordionDetails>
        </Accordion>
      ) : (
        <>
          <Tabs
            value={currentTabIndex - (currentTabIndex-getEarliestActiveChild(tabs[mainTabIndex - 1]))}
            onChange={(t, v) => { handleTabChange(v); }}
            centered
            indicatorColor="secondary"
            textColor="secondary"
          >
            {tabs.map((tab) =>
              showParentTab(tab) ? <Tab sx={{ textTransform: "none", color: "#1A0062", fontFamily: "Satisfy; cursive", fontSize: "36px", fontWeight: "500" }} key={tab.label} label={tab.label} value={getEarliestActiveChild(tab)} /> : null
            )}
          </Tabs>

          <Tabs
            value={currentTabIndex}
            onChange={(t, v) => { handleTabChange(v); }}
            centered
            indicatorColor="secondary"
            textColor="secondary"
            hidden={tabs[mainTabIndex - 1]?.children && tabs[mainTabIndex - 1]?.children.length > 0}
          >
            {tabs[mainTabIndex - 1]?.children && tabs[mainTabIndex - 1]?.children.length > 0 && tabs[mainTabIndex - 1]?.children.map((child) =>
              showChildTab(child) ? <Tab sx={{ textTransform: "none", color: "#1A0062", fontFamily: "Satisfy; cursive", fontSize: "30px", fontWeight: "500" }} key={child.label} label={child.label} value={child.value} /> : null
            )}
          </Tabs>
        </>
      )}
    </Box>
  );
};

export default TabComponent;
