
import { Grid } from '@mui/material';

const PartyGoer = ({
    name,
    upperTitle,
    lowerTitle,
    img,
    quote,
    largeImg,
}) => {
    largeImg &= true;

    const getQuote = () => {
        let splits = quote?.split("\n");
        return (<>
            {splits?.map(split => {
                return (
                    <>{split}<br/></>
                )
            }
            )}
            </>
        )
    }
    return (
        <Grid container>
            
            <Grid item xs={12}><h3 style={{ margin: "0px 10px" }}>{name}</h3></Grid>
            
            <Grid item xs={12}>{upperTitle}</Grid>
            
            <Grid item xs={12}><img width="75%"
                src={`./images/party/${img === undefined || img === null ? "user.png" : img}`}
                alt={name}
                loading="eager"
                style={{ maxHeight: largeImg ? "100%" : "311.8px", margin: "25px"}}
            /></Grid>
            
            <Grid item xs={12}>{lowerTitle} </Grid>
            
            <Grid item xs={12}><p style={{ width: "80%", margin: "0% 10%"}}>
                {getQuote()}
                </p></Grid>
                </Grid>
    );
}

export default PartyGoer;