import { MenuItem, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const TabAccordion = ({ tab, handleTabChange, showChildTab }) => {
    
  const getMenuItem = (tab) => {
    if (showChildTab(tab)) {
    return (
      <MenuItem
        key={tab.label}
        value={tab.value}
        style={{ width: "90vw", color: "#1A0062", textAlign: "center" }}
        onClick={() => handleTabChange(tab.value)}>
        <h4 style={{ margin: "0px", padding: "0px 20px"}}>{tab.label}</h4>
      </MenuItem>
    );}
    return <></>;
  }

    return (<>
        {tab?.children?.length > 0 ?
          <Accordion key={tab.label} style={{ color: "#1A0062", backgroundColor: "#fff6e6"}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
              <h4 style={{ margin: "0px", padding: "0px 20px"}}>{tab.label}</h4>
            </AccordionSummary>
            <AccordionDetails>
              {tab?.children?.map((child) => getMenuItem(child))}
            </AccordionDetails>
          </Accordion>
          :
          getMenuItem(tab)}
      </>
      );
}

export default TabAccordion;