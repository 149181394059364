import React, { useState } from "react";
import { IconButton, Box, InputAdornment, FormControl, InputLabel, FilledInput } from "@mui/material";
import CustomButton from "../../Components/Buttons/Button";
import { useDispatch } from "react-redux";
import { login } from "../../Store/actions/userActions";
import { AccountCircle, Visibility, VisibilityOff } from "@mui/icons-material";
import LockIcon from '@mui/icons-material/Lock';

const LoginComponent = ({ xs }) => {
  const dispatch = useDispatch();
  var [username, setUsername] = useState("");
  var [password, setPassword] = useState("");
  var [obfuscate, setObfuscation] = useState(true);

  const handleLogin = async () => {
    dispatch(login(username, password));
    setPassword("");
  };

  const toggleObfuscate = () => {
    setObfuscation(!obfuscate);
  };

  return (
    <Box>
      {xs ? <h1 style={{ margin: 0 }}>Login</h1> : <h1>Login</h1>}
      <FormControl sx={{
        m: 1, width: '25ch',
      }} variant="filled">
        <InputLabel htmlFor="filled-adornment-username">Username</InputLabel>
        <FilledInput
          id="filled-adornment-username"
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          sx={{ marginTop: "10px", fontSize: "24px" }}
          startAdornment={
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          }
        />
      </FormControl>
      <br />
      <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
        <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
        <FilledInput
          id="filled-adornment-password"
          type={!obfuscate ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ marginTop: "10px", fontSize: "24px" }}
          startAdornment={
            <InputAdornment position="start">
              <LockIcon />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={toggleObfuscate}
                edge="end"
              >
                {!obfuscate ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <br />
      <FormControl sx={{ m: 2, width: '25ch' }} variant="filled">
        <CustomButton
          onClick={() => handleLogin()}
          text="Login"
        />
      </FormControl>
    </Box>
  );
};

export default LoginComponent;
