import { Auth } from "aws-amplify";
import { StoreConstants } from "../storeConstants";
import { getCurrentUser } from "./apiActions";
import { setDefaultTimedError } from "./errorActions";
import awsConfig from "../../config/awsConfig";
import axios from "axios";

export const refreshCurrentSession = () => {
  return (dispatch) => {
    Auth.currentSession()
      .then((response) => {
        const userId = response.getIdToken().payload["custom:userId"] ?? -1;
        const token = response.getIdToken().getJwtToken();
        const admin = response.getIdToken().payload["cognito:groups"].some((group) => (group === "our-admins-user-group"));
        dispatch({
          type: StoreConstants.REFRESH_CURRENT_SESSION_SUCCESS,
          payload: {
            userId: userId,
            isAdmin: admin,
            token: token,
          },
        });
        dispatch(getCurrentUser());
        dispatch({
          type: StoreConstants.CLEAR_ERROR
        });
      })
      .catch((error) => {
        dispatch(setDefaultTimedError(error))
      });
  };
};

export const login = (username, password) => {
  return (dispatch) => {
    Auth.signIn(username, password).then((response) => {
      dispatch({
        type: StoreConstants.CLEAR_ERROR
      });
      if (response?.challengeName) {
        dispatch({
          type: response.challengeName,
          payload: response,
        });
      } else {
        dispatch({
          type: StoreConstants.LOGIN_SUCCESS,
        });
        dispatch(refreshCurrentSession());
      }
    }).catch((error => {
      dispatch(setDefaultTimedError(error))
    }));
  };
};

export const changePassword = (newPassword) => {
  return (dispatch, getState) => {
    const { cognitoUser } = getState().userReducer;
    Auth.completeNewPassword(cognitoUser, newPassword)
      .then((response) => {
        dispatch({
          type: StoreConstants.CLEAR_ERROR
        });
        dispatch(refreshCurrentSession());
        dispatch({ type: StoreConstants.LOGIN_SUCCESS });
      })
      .catch((error) => {
        dispatch(setDefaultTimedError(error))
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    Auth.signOut()
      .then(() => {
        dispatch({
          type: StoreConstants.CLEAR_ERROR
        });
        dispatch({ type: StoreConstants.LOGOUT_SUCCESS });
      })
      .catch((error) => {
        dispatch(setDefaultTimedError(error))
      });
  };
};

const featuresEndpoint = `${awsConfig.api_endpoint}/features`;
export const getFeatureToggles = () => {
  return (dispatch, getState) => {
    dispatch({type: StoreConstants.LOADING});
    axios
      .get(`${featuresEndpoint}`, {
        headers: getHeaders(getState),
      })
      .then((response) => {
        dispatch({
          type: StoreConstants.GET_FEATURE_TOGGLES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: StoreConstants.GET_FEATURE_TOGGLES_FAILED
        });
        dispatch(setDefaultTimedError(error))
      });
  }
}
export const setFeatureToggles = (toggles) => {
  return (dispatch, getState) => {
    dispatch({type: StoreConstants.LOADING});
    axios
      .put(`${featuresEndpoint}`, toggles, {
        headers: getHeaders(getState),
      })
      .then((response) => {
        dispatch({
          type: StoreConstants.PUT_FEATURE_TOGGLES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch(setDefaultTimedError(error));
      });
  };
}

const getHeaders = (getState) => {
  const { session } = getState().userReducer;
  return {
    Authorization: `Bearer ${session.token}`,
    "Content-Type": "application/json",
  };
};
