import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../Components/Buttons/Button";
import { StoreConstants } from "../../Store/storeConstants";
import { sendEmail } from "../../Store/actions/communicationActions";

const SendEmail = () => {
  const dispatch = useDispatch();

  const { message, subject, filter } = useSelector((state) => state.communicationReducer);

  const handleAllChange = (e) => {
    dispatch({
      type: StoreConstants.SET_EMAIL_FILTER,
      payload: "all"
    })
  }
  const handleYesChange = (e) => {
    dispatch({
      type: StoreConstants.SET_EMAIL_FILTER,
      payload: "yes"
    })
  }
  const handleNoneChange = (e) => {
    dispatch({
      type: StoreConstants.SET_EMAIL_FILTER,
      payload: "none"
    })
  }
  const handleSubjectChange = (value) => {
    dispatch({
      type: StoreConstants.SET_EMAIL_SUBJECT,
      payload: value
    })
  }
  const handleMessageChange = (value) => {
    dispatch({
      type: StoreConstants.SET_EMAIL_MESSAGE,
      payload: value
    })
  }

  return (
    <Grid container>
      <Grid item xs={1} md={3} />
      <Grid item xs={10} md={6}>
        <h1>Send Email Message</h1>
        <h4>Use this page to send your guests an email</h4>
        <p>
          <TextField
            variant="outlined"
            fullWidth
            value={subject}
            onChange={(e) => {
              handleSubjectChange(e.target.value);
            }}
            id={"subject"}
            autoComplete="off"
            label="Subject"
            multiline={false}
          />
        </p>
        <p>
          <TextField
            variant="outlined"
            fullWidth
            value={message}
            onChange={(e) => {
              handleMessageChange(e.target.value);
            }}
            id={"message"}
            autoComplete="off"
            label="Message"
            multiline={true}
            fullHeight
          />
        </p>
        <p>
        <Grid container>
          <Grid item xs={0} md={10}>
          <FormControlLabel
        control={
          <Checkbox
            checked={filter==="all"}
            onChange={handleAllChange}
            color="primary"
          />
        }
        label="All Invited Guests"
      />
            <FormControlLabel
        control={
          <Checkbox
            checked={filter==="yes"}
            onChange={handleYesChange}
            color="primary"
          />
        }
        label="Responded Yes"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={filter==="none"}
            onChange={handleNoneChange}
            color="primary"
          />
        }
        label="Have not Responded"
      />
      </Grid>
          <Grid item xs={12} md={2}>
            <CustomButton onClick={() => {
              dispatch(sendEmail())
            }} text="Send Email" />
          </Grid>
        </Grid>
        </p>
      </Grid>
      <Grid item xs={1} md={3} />
    </Grid>
  );
};

export default SendEmail;
