import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import React, { useState } from "react";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ResetDialog from "../../Pages/Admin/ResetDialog";
import '../../App.css';
import CustomButton from "../Buttons/Button";
import { useTheme } from "@emotion/react";

const Invitee = ({
  invitee,
  allInvitees,
  handleInviteeChange,
  handleRsvpChange,
  handleSendInvitation,
  handleEditOrConfirm,
  onDelete,
  handleUserReset
}) => {
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);

  const handleSendInvitationWithDialog = (id) => {
    if (invitee.inviteSent) {
      setOpen(true);
    } else {
      handleSendInvitation(id);
    }
  };

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));

  const toProperCase = (text) => {
    return text.substring(0, 1).toUpperCase().concat(text.substring(1));
  }

  const removeAdditionalGuest = (i) => {
    const temporaryNames = [];
    invitee.additionalGuests.forEach((val, index) => {
      if (i !== index) {
        temporaryNames.push(val);
      }
    })
    handleInviteeChange(invitee.id, "additionalGuests", temporaryNames);
  }

  const addAdditionalGuest = () => {
    handleInviteeChange(invitee.id, "additionalGuests", [...invitee.additionalGuests, {
      id: null,
      name: null
    }]);
  }

  const changeLinkedGuest = (lgIndex, guestId) => {
    let temp = [...invitee.additionalGuests];
    temp[lgIndex] = {
      id: null,
      name: null
    };
    if (guestId) {
      const linkedGuest = allInvitees.find(inv => inv.id === guestId);
      temp[lgIndex] = {
        id: linkedGuest.id,
        name: linkedGuest.label
      };
    }
    handleInviteeChange(invitee.id, "additionalGuests", temp);
  };

  const getIcons = () => {
    return (<>
      {invitee.isEditMode && (
        <FormControl sx={{ width: "90%", margin: "0" }}>
          <Select sx={{ width: "100%" }} value={invitee.rsvp.wedding}>
            <MenuItem value="yes" onClick={() => handleRsvpChange(invitee.id, {
              ...invitee.rsvp,
              wedding: "yes",
            })}>
              <CheckCircleIcon
                color={"success"}
              />
            </MenuItem>
            <MenuItem value="none" onClick={() => handleRsvpChange(invitee.id, {
              ...invitee.rsvp,
              wedding: "none",
            })}>
              <HourglassEmptyIcon
                color={"warning"}
              />
            </MenuItem>
            <MenuItem value="no" onClick={() => handleRsvpChange(invitee.id, {
              ...invitee.rsvp,
              wedding: "no",
            })}>
              <CancelIcon
                color={"error"}
              />
            </MenuItem>
          </Select>
        </FormControl>
      )}
      <p>
        {!invitee.isEditMode && invitee.rsvp.wedding === "yes" && (
          <CheckCircleIcon color="success" />
        )}
        {!invitee.isEditMode && invitee.rsvp.wedding === "no" && (
          <CancelIcon color="error" />
        )}
        {!invitee.isEditMode && invitee.rsvp.wedding === "none" && (
          <HourglassEmptyIcon color="warning" />
        )}</p>

    </>);
  }
  return (
    <Accordion expanded={expanded} style={{
      color: "black",
      fontFamily: "Satisfy",
      fontSize: "calc(14px + 2vmin)"
    }}>
      <AccordionSummary>
        <Grid container>
          <Grid item style={{ marginRight: "35px" }}>
            <IconButton
              style={{ color: "black" }}
              onClick={() => {
                setExpanded(!expanded);
              }}
            >
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Grid>
          <Grid item xs={11} md={2}>
            {invitee.isEditMode ? (
              <TextField
                variant="outlined"
                style={{ border: "1px solid black" }}
                fullWidth
                value={invitee?.name ?? null}
                onChange={(event) =>
                  handleInviteeChange(invitee.id, "name", event.target.value)
                }
                id={`user-name-${invitee.id}`}
                autoComplete="off"
              />
            ) : (
              <p
                style={{ textAlign: "left" }}
              >
                {invitee?.name}
              </p>
            )}
          </Grid>
          <Grid item xs={12} md={3}>
            {invitee.isEditMode ? (
              <TextField
                variant="outlined"
                style={{ border: "1px solid black" }}
                fullWidth
                value={invitee?.email}
                onChange={(event) =>
                  handleInviteeChange(invitee.id, "email", event.target.value)
                }
                id={`user-email-${invitee.id}`}
                autoComplete="off"
              />
            ) : (
              <p style={{ wordWrap: "break-word", textAlign: "left" }}>{invitee?.email}</p>
            )}
          </Grid>
          <Grid item style={{ width: "50px" }} />
          <Grid item xs={12} md={3} style={{ textAlign: "left" }}>
            <Grid container>
              <Grid item xs={2} style={{ textAlign: "left" }}>
                <Tooltip title="Full Accomodation">
                  <p><IconButton

                    style={{ color: "black" }}
                    onClick={() =>
                      handleInviteeChange(
                        invitee.id,
                        "fullAccomodation",
                        !invitee?.fullAccomodation
                      )
                    }
                    disabled={!invitee.isEditMode}
                  >
                    {invitee?.fullAccomodation ? (
                      <DoneOutlineIcon />
                    ) : (
                      <CloseOutlinedIcon />
                    )}
                  </IconButton></p>
                </Tooltip>
              </Grid>
              <Grid item xs={2} style={{ textAlign: "left" }}>
                <Tooltip title="Friday Night">
                  <p><IconButton

                    style={{ color: "black" }}
                    onClick={() =>
                      handleInviteeChange(
                        invitee.id,
                        "fridayNight",
                        !invitee?.fridayNight
                      )
                    }
                    disabled={!invitee.isEditMode}
                  >
                    {invitee?.fridayNight ? (
                      <DoneOutlineIcon />
                    ) : (
                      <CloseOutlinedIcon />
                    )}
                  </IconButton></p>
                </Tooltip>
              </Grid>
              <Grid item xs={2} style={{ textAlign: "left" }}>
                <Tooltip title="Bachelorette">
                  <p><IconButton

                    style={{ color: "black" }}
                    onClick={() =>
                      handleInviteeChange(
                        invitee.id,
                        "bachelorette",
                        !invitee?.bachelorette
                      )
                    }
                    disabled={!invitee.isEditMode}
                  >
                    {invitee?.bachelorette ? (
                      <DoneOutlineIcon />
                    ) : (
                      <CloseOutlinedIcon />
                    )}
                  </IconButton></p>
                </Tooltip>
              </Grid>
              <Grid item xs={2} style={{ textAlign: "left" }}>
                <Tooltip title="Kitchen Tea">
                  <p><IconButton

                    style={{ color: "black" }}
                    onClick={() =>
                      handleInviteeChange(
                        invitee.id,
                        "gardenTea",
                        !invitee?.gardenTea
                      )
                    }
                    disabled={!invitee.isEditMode}
                  >
                    {invitee?.gardenTea ? (
                      <DoneOutlineIcon />
                    ) : (
                      <CloseOutlinedIcon />
                    )}
                  </IconButton></p>
                </Tooltip>
              </Grid>
              <Grid item xs={2} style={{ textAlign: "left" }}>
                <Tooltip title="Bachelors">
                  <p><IconButton

                    style={{ color: "black" }}
                    onClick={() =>
                      handleInviteeChange(
                        invitee.id,
                        "bachelors",
                        !invitee?.bachelors
                      )
                    }
                    disabled={!invitee.isEditMode}
                  >
                    {invitee?.bachelors ? (
                      <DoneOutlineIcon />
                    ) : (
                      <CloseOutlinedIcon />
                    )}
                  </IconButton></p>
                </Tooltip>
              </Grid>
              {xs && <Grid item xs={2} style={{ textAlign: "left" }}> {getIcons()} </Grid>}
            </Grid>
          </Grid>
          <Grid item xs={12} md={1}>
            {!xs && getIcons()}
          </Grid>
          <Grid item style={{ width: "75px" }}></Grid>
          <Grid item xs={12} md={1} style={{ textAlign: "right" }}><p>
            <IconButton
              style={{ color: "black" }} onClick={() => handleSendInvitationWithDialog(invitee.id)}>
              <MailOutlineIcon
                color={invitee.inviteSent ? "success" : "error"}
              />
            </IconButton>
            <IconButton
              style={{ color: "black" }} onClick={() => handleEditOrConfirm(invitee)}>
              {invitee.isEditMode ? <DoneOutlineIcon /> : <EditOutlinedIcon />}
            </IconButton>
            <IconButton
              style={{ color: "black" }} onClick={() => onDelete(invitee.id)}>
              <DeleteOutlineIcon />
            </IconButton></p>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={12} md={!invitee.isEditMode && invitee.additionalGuests.length < 1 ? 6 : 4}>
            <h3>{invitee.name}</h3>
            <p>Wedding RSVP: {toProperCase(invitee.rsvp.wedding)}</p>
            <p>
              Attending the bachelors:{" "}
              {invitee.rsvp.bachelors ? "Yes" : "No"}
            </p>
            <p>
              Attending the bachelorette:{" "}
              {invitee.rsvp.bachelorette ? "Yes" : "No"}
            </p>
            <p>
              Dietary Restrictions:{" "}
              {invitee.rsvp.dietaryRestrictions}
            </p>
            <p>
              Staying at Venue:{" "}
              {invitee.rsvp.accomodation ? "Yes" : "No"}
            </p>
            {!invitee.rsvp.accomodation &&
              <p>
                Sunday Breakfast:{" "}
                {invitee.rsvp.breakfast ? "Yes" : "No"}
              </p>}
          </Grid>
          <Grid item xs={12} md={4} hidden={!invitee.isEditMode && invitee.additionalGuests.length < 1} >
            <h3>Linked Guests:</h3>
            <Grid container>
              {
                invitee.additionalGuests.map((guest, i) => {
                  return (
                    <Grid item xs={12} key={i}>
                      {
                        invitee.isEditMode ? <Grid container>
                          <Grid item xs={11}>
                            <Autocomplete
                              value={guest?.name}
                              onChange={(e, newValue) => changeLinkedGuest(i, newValue?.id)}
                              options={allInvitees}
                              renderInput={(params) => <TextField {...params} label="Other Guest" />}
                              style={{ width: "90%" }}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <CustomButton
                              onClick={() => { removeAdditionalGuest(i); }}
                              text="-"
                            /></Grid>
                        </Grid>
                          :
                          <p style={{ wordWrap: "break-word", textAlign: "left", width: "100%" }}>{guest.name}</p>
                      }
                    </Grid>
                  );
                })
              }
            </Grid>
            <br />
            {!invitee.isEditMode ? <></> : <>

              <CustomButton
                onClick={() => {
                  addAdditionalGuest();
                }}
                text="+"
              />
            </>}
          </Grid>
          <Grid item xs={12} md={!invitee.isEditMode && invitee.additionalGuests.length < 1 ? 6 : 4}>
            <h3>Song Request:</h3>
            <p>{invitee.rsvp.songRequest.title} </p>
            <p>{invitee.rsvp.songRequest.artist} </p>
            <p>{invitee.rsvp.songRequest.url} </p>
          </Grid>
        </Grid>
        <ResetDialog
          open={open}
          setOpen={setOpen}
          onConfirm={() => handleUserReset(invitee.id)}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default Invitee;
