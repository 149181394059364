import { Button } from "@mui/material";


const CustomButton = (props) => {
    return (
    <Button variant="contained" color="error" onClick={() => props.onClick()}>
    {props.text}
  </Button>)
};

export default CustomButton;