export const StoreConstants = {
    REFRESHING_CURRENT_SESSION: "REFRESHING_CURRENT_SESSION",
    REFRESH_CURRENT_SESSION_SUCCESS: "REFRESH_CURRENT_SESSION_SUCCESS",
    REFRESH_CURRENT_SESSION_FAILED: "REFRESH_CURRENT_SESSION_FAILED",
    NEW_PASSWORD_REQUIRED: "NEW_PASSWORD_REQUIRED",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILED: "LOGIN_FAILED",
    LOGOUT_FAILED: "LOGOUT_FAILED",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    LOADING: "LOADING",
    CLEAR_LOADING: "CLEAR_LOADING",
    GET_ALL_INVITEES_SUCCESS: "GET_ALL_INVITEES_SUCCESS",
    GET_ALL_INVITEES_FAILED: "GET_ALL_INVITEES_FAILED",
    GET_CURRENT_INVITEE_SUCCESS: "GET_CURRENT_INVITEE_SUCCESS",
    GET_CURRENT_INVITEE_FAILED: "GET_CURRENT_INVITEE_FAILED",
    GET_LINKED_INVITEE_SUCCESS: "GET_LINKED_INVITEE_SUCCESS",
    GET_LINKED_INVITEE_FAILED: "GET_LINKED_INVITEE_FAILED",
    GET_INVITEE_SUCCESS: "GET_INVITEE_SUCCESS",
    GET_INVITEE_FAILED: "GET_sINVITEE_FAILED",
    SAVE_INVITEE_SUCCESS: "SAVE_INVITEE_SUCCESS",
    SAVE_INVITEE_FAILED: "SAVE_INVITEE_FAILED",
    SEND_INVITATION_SUCCESS: "SEND_INVITATION_SUCCESS",
    SEND_INVITATION_FAILED: "SEND_INVITATION_FAILED",
    DELETE_INVITEE_SUCCESS: "DELETE_INVITEE_SUCCESS",
    DELETE_INVITEE_FAILED: "DELETE_INVITEE_FAILED",
    ADD_EMPTY_INVITEE: "ADD_EMPTY_INVITEE",
    UPDATE_USER: "UPDATE_USER",
    S3_UPLOAD_SUCCESS: "S3_UPLOAD_SUCCESS",
    S3_UPLOAD_FAILED: "S3_UPLOAD_FAILED",
    UPDATE_UPLOAD_FILES: "UPDATE_UPLOAD_FILES",
    CLEAR_IMAGES: "CLEAR_IMAGES",
    GENERAL_ERROR: "GENERAL_ERROR",
    CLEAR_ERROR: "CLEAR_ERROR",
    GET_PHOTOS_SUCCESS: "GET_PHOTOS_SUCCESS",
    PUT_PHOTO_SUCCESS: "PUT_PHOTO_SUCCESS",
    DELETE_PHOTO_SUCCESS: "DELETE_PHOTO_SUCCESS",
    GET_SPOTIFY_TOKEN_SUCCESS: "GET_SPOTIFY_TOKEN_SUCCESS",
    GET_SONG_SUCCESS: "GET_SONG_SUCCESS",
    ADD_SONG_SUCCESS: "ADD_SONG_SUCCESS",
    CLEAR_SONG: "CLEAR_SONG",
    SEND_EMAIL_SUCCESS: "SEND_EMAIL_SUCCESS",
    SET_EMAIL_MESSAGE: "SET_EMAIL_MESSAGE",
    SET_EMAIL_SUBJECT: "SET_EMAIL_SUBJECT",
    SET_EMAIL_FILTER: "SET_EMAIL_FILTER",
    GET_FEATURE_TOGGLES_SUCCESS: "GET_FEATURE_TOGGLES_SUCCESS",
    GET_FEATURE_TOGGLES_FAILED: "GET_FEATURE_TOGGLES_FAILED",
    PUT_FEATURE_TOGGLES_SUCCESS: "PUT_FEATURE_TOGGLES_SUCCESS",
}