import { Grid } from "@mui/material";
import { PhotoFrame } from "../../Components/PhotoComponents/PhotoFrame";

export const Photos = () => {
  return (
    <Grid container overflow="hidden" justifyContent="center">
      <Grid item xs={12}  style={{ textAlign: "center" }}>
        <h1>Photos</h1>
      </Grid>
      <Grid xs={12}>
        <PhotoFrame />
      </Grid>
    </Grid>
  );
};
