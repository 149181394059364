import { StoreConstants } from "../storeConstants";

const initialState = {
  images: [],
  imagesToUpload: [],
  isLoading: false,
};

const photoReducer = (state = initialState, action) => {
  switch (action.type) {
    case StoreConstants.LOADING:
      return {
        ...state, 
        isLoading: true,
      }
      case StoreConstants.CLEAR_LOADING:
        return {
          ...state, 
          isLoading: false,
        }
    case StoreConstants.CLEAR_IMAGES:
      return {
        ...state,
        images: [],
      };
    case StoreConstants.UPDATE_UPLOAD_FILES:
      return {
        ...state,
        imagesToUpload: action.payload,
      };
    case StoreConstants.GET_PHOTOS_SUCCESS:
      return {
        ...state,
        images: action.payload,
        isLoading: false,
      }
    case StoreConstants.PUT_PHOTO_SUCCESS:
      return {
        ...state,
        imagesToUpload: []
      }
    default:
      return state;
  }
};

export default photoReducer;
