import { useEffect, useState } from "react";
import { Grid, IconButton, useMediaQuery, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import WeddingInfo from "./WeddingInfo";
import { Photos } from "./Photos";
import { useDispatch, useSelector } from "react-redux";
import { getFeatureToggles, logout } from "../../Store/actions/userActions";
import Invitees from "../Admin/Invitees";
import Countdown from "react-countdown";
import LogoutIcon from "@mui/icons-material/Logout";
import Venues from "./Venues";
import Gifts from "./Gifts";
import TabComponent from "../../Components/TabComponent";
import Bachelors from "./Bachelors";
import Bachelorette from "./Bachelorette";
import KitchenTea from "./KitchenTea";
import SendEmail from "../Admin/SendEmail";
import RSVP from "../../Components/RSVP/RSVP";
import { MobileCountdown } from "../../Components/Countdown/MobileCountdown";
import { LargeCountdown } from "../../Components/Countdown/LargeCountdown";
import WeddingParty from "./WeddingParty";
import { tabs_ } from "../tabs";
import FeatureToggles from "../Admin/FeatureToggles";
import { getCookie, setCookie } from "../../Utils/InviteeUtil";
import FAQs from "./FAQ";
import RehearsalDinner from "./RehearsalDinner";
import SundayBreakfast from "./SundayBreakfast";

const valueToConponentMapper = (value) => {
  switch (value) {
    case 10:
      return <WeddingInfo />;
    case 11:
      return <WeddingParty />;
    case 12:
      return <Venues />;
    case 13:
      return <FAQs/>;
    case 20:
      return <RehearsalDinner/>;
    case 21:
      return <Bachelors />;
    case 22:
      return <Bachelorette />;
      case 23:
        return <KitchenTea />;
        case 24:
          return <SundayBreakfast/>;
    case 30:
      return <RSVP />;
    case 40:
      return <Gifts />;
    case 50:
      return <Photos />;
    case 60:
      return <Invitees />;
    case 61:
      return <SendEmail />;
      case 62: 
      return <FeatureToggles />;
    default:
      return <></>;
  }
}

const Home = () => {

  const [currentTabIndex, setCurrentTabIndex] = useState(10);
  const dispatch = useDispatch();

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const handleTabChange = (tabIndex) => {
    setCookie("tab_index", tabIndex, 1);
    setCurrentTabIndex(tabIndex);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const getCurrentTab = () => {
    return valueToConponentMapper(currentTabIndex);
  };

  const { session, features } = useSelector((state) => state.userReducer);
  const { currentInvitee } = useSelector((state) => state.apiReducer);
  const [tabs, setTabs] = useState([]);
  const [backgroundImage, setBackgroundImage] = useState(null);

  const getBackgroundUrl = (md) => {
    if (md) {
      return "WeddingLogoWatermark";
    }
    // return "WeddingLogoWatermarkMobile";
      return "WeddingLogoWatermark";
  }

  useEffect(() => {
    setBackgroundImage(getBackgroundUrl(md));
    const previousTabIndex = getCookie("tab_index");
    if (previousTabIndex !== "") {
      handleTabChange(parseInt(previousTabIndex));
    }
    dispatch(getFeatureToggles());
    setTabs(tabs_);
  }, [dispatch, session, md, currentInvitee]);

  return (
    <Box overflow="hidden">
      <Box
        sx={{
          position: "fixed",
          top: 5,
          right: -5,
          zIndex: 10,
        }}
      >
        <IconButton onClick={() => handleLogout()} text={"Log Out"}>
          <LogoutIcon />
        </IconButton>
      </Box>
      <Grid container>
        {!xs && (
          <Grid item xs={0} sm={3} sx={{ paddingLeft: "3vw" }}>
            <img src="/WeddingLogoSVG.svg" style={{ width: "16vw", }} alt="MGlogo" />
          </Grid>
        )}
        <Grid item xs={12} sm={6} style={{ paddingTop: xs ? "10px" : "0px" }}>
          <Typography variant="h1">
            Gabriella & Marco</Typography>
          {xs ? <></> : <Typography variant="h1">Bushveld Bliss!</Typography>}
        </Grid>
        <Grid item xs={12} sm={3} style={{ textAlign: "center" }}>
          <Box style={{ border: "2xp solid black", width: "100%", height: "100%" }}>
            <Countdown
              date={Date.parse("2025/03/22 16:00:00")}
              renderer={md ? MobileCountdown : LargeCountdown}
              style={{ width: "100%", height: "100px" }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TabComponent
            tabs={tabs}
            handleTabChange={handleTabChange}
            currentTabIndex={currentTabIndex}
            featureToggles={features}
          />
        </Grid>
      </Grid>
      <br />
      <Box
        style={{
          overflowX: "auto",
          overflowY: "auto",
          border: `${md ? 2 : 5}px solid #A4602F`,
          width: `${xs ? 89 : 94}%`,
          marginLeft: `${xs ? 5 : 3}%`,
          padding: `0px`,
          position: "relative",
          backgroundImage: `url('/${backgroundImage}.png')`, // Replace with the actual path to your watermark image
          backgroundPosition: "center", // Adjust as needed
          backgroundRepeat: "no-repeat", // Adjust as needed
          minHeight: "75vh"
        }}>
        {getCurrentTab()}
      </Box>
    </Box>
  );
};

export default Home;
