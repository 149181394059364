import axios from "axios";
import { StoreConstants } from "../storeConstants";
import awsConfig from "../../config/awsConfig";
import { getHeaders } from "../general";
import { setDefaultTimedError } from "./errorActions";

const spotifyEndpoint = `${awsConfig.api_endpoint}/spotify`;

export const getSpotifyAuthToken = (searchUrl = null) => {
  return (dispatch, getState) => {
    axios
      .get(`${spotifyEndpoint}`, {
        headers: getHeaders(getState),
      })
      .then((response) => {
        dispatch({
          type: StoreConstants.GET_SPOTIFY_TOKEN_SUCCESS,
          payload: response.data,
        });
        if (searchUrl !== null) {
          dispatch(
            searchSpotify(searchUrl)
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: StoreConstants.GENERAL_ERROR,
          payload: error,
        });
      });
  };
};

export const searchSpotify = () => {
  return (dispatch, getState) => {
    const { authToken, searchUrl } = getState().spotifyReducer;
    if (authToken === null || authToken === "") {
      dispatch(getSpotifyAuthToken(searchUrl));
    }
    else {
    const id = searchUrl.split("/track/")[1].split("?")[0];
    axios
      .get(`https://api.spotify.com/v1/tracks/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const song = response.data;
        const artists = song.artists.reduce((prev, curr) => {
          return prev.concat(`${curr.name}, `);
        }, "");
        dispatch({
          type: StoreConstants.GET_SONG_SUCCESS,
          payload: {
            title: song.name,
            artists: artists.substring(0, artists.length-2),
            imgUrl: song.album.images[0].url,
            uri: song.uri,
          },
        });
      })
      .catch((error) => {
        dispatch(setDefaultTimedError(error))
      });
    }
  };
};

export const addSongToPlaylist = () => {
  return (dispatch, getState) => {
    const { authToken, song } = getState().spotifyReducer;
    axios
      .post(
        `https://api.spotify.com/v1/playlists/2DAGloBZRHBKLVpSy9n7Zk/tracks`,
        [song.uri],
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        dispatch({
          type: StoreConstants.ADD_SONG_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch(setDefaultTimedError(error))
      });
  };
};
