import { StoreConstants } from "../storeConstants";

const initialState = {
  authToken: "",
  searchUrl: null,
  song: {
    title: "",
    artist: "",
    imgUrl: "",
  },
};

const spotifyReducer = (state = initialState, action) => {
  switch (action.type) {
    case StoreConstants.GET_SONG_SUCCESS:
      return {
        ...state,
        song: action.payload,
      };
    case StoreConstants.GET_SPOTIFY_TOKEN_SUCCESS:
      return {
        ...state,
        authToken: action.payload,
      };
      case StoreConstants.CLEAR_SONG:
        return {
          ...state,
          song: {
            title: "",
            artist: "",
            imgUrl: "",
          }
        }
        case "CHANGE_SEARCH_URL":
          return {
            ...state,
            searchUrl: action.payload
          }
    default:
      return state;
  }
};

export default spotifyReducer;
