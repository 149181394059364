
import { Grid, IconButton, Switch } from "@mui/material";
import { tabs_ } from "../tabs";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setFeatureToggles } from "../../Store/actions/userActions";
import { Save } from "@mui/icons-material";

const FeatureToggles = () => {

    const { features } = useSelector((state) => state.userReducer);

    const [ft, setFt] = useState({});
    const dispatch = useDispatch();

    const getFeatureLabel = (key) => {
        for (const tab of tabs_) {
            if (tab?.key === key) return tab.label;
            if (tab?.children?.length) {
                for (const child of tab?.children) {
                    if (child.key === key) return child.label;
                }
            }
        }
    }

    const handleToggleChange = (key) => {
        const tmp = { ...ft };
        tmp[key] = !ft[key];
        setFt(tmp);
    }

    const getFeatures = () => {
        const ftgs = []
        for (const f in features) {
            ftgs.push(
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={8}>
                            <h4>{getFeatureLabel(f)}</h4>
                        </Grid>
                        <Grid item xs={4}>
                            <Switch
                                checked={ft[f]}
                                color="warning"
                                onChange={() => { handleToggleChange(f) }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
        return ftgs;
    }

    useEffect(() => {
        setFt(features)
    }, [features, setFt]);

    const save = () => {
        dispatch(setFeatureToggles(ft));
    }

    return (
        <Grid container>
            <Grid item xs={1} md={3} />
            <Grid item xs={10} md={6}>
                <h3>Feature Toggles</h3>
                <IconButton
                    style={{ color: "white" }} onClick={() => save()}>
                    <Save color="primary" />
                </IconButton>
                <Grid container>
                    {getFeatures()}
                </Grid>
            </Grid>
            <Grid item xs={1} md={3} />
        </Grid>
    );

}

export default FeatureToggles;