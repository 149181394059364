import { useDispatch, useSelector } from "react-redux";
import Home from "./User/Home";
import LoginPage from "./User/login";
import { useEffect } from "react";
import { refreshCurrentSession } from "../Store/actions/userActions";
import { Alert, Box, IconButton, Snackbar } from "@mui/material";
import SpotifyWidget from "../Components/Playlist/SpotifyWidget";
import { clearError } from "../Store/actions/errorActions";
import { Close } from "@mui/icons-material";
const MainComponent = () => {

  const { session } = useSelector((state) => state.userReducer);
  const { isError, errorMessage } = useSelector((state) => state.errorReducer);

  const v = "top";
  const h = "center";

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(clearError());
  }
  useEffect(() => {
    // Function to fetch data from the API
    async function fetchData() {
      dispatch(refreshCurrentSession());
    }
    // Call the fetchData function when the component mounts
    fetchData();
  }, [dispatch]);

  return (
    <>
      <Box overflow="hidden">
        <Snackbar
          open={isError}
          autoHideDuration={5000}
          onClose={handleClose}
          color="red"
          action={<IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}>
            <Close />
          </IconButton>}
        >
          <Alert
            onClose={handleClose}
            severity="error"
            variant="filled"
            sx={{ width: '100%' }}
          >
          {errorMessage?.message}</Alert>
        </Snackbar>
        {session === null ? <LoginPage /> : <Home />}
      </Box>
      <SpotifyWidget />
      <p>V3.0 © 2023 Marco Benvenuti - All rights reserved.</p>
    </>
  );
};

export default MainComponent;
