import { StoreConstants } from "../storeConstants";

const initialState = {
  newPasswordRequired: false,
  cognitoUser: {},
  session: null,
  features: {}
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case StoreConstants.REFRESH_CURRENT_SESSION_SUCCESS: {
      return {
        ...state,
        session: action.payload,
        cognitoUser: null,
        isError: false,
        errorMessage: "",
      };
    }
    case StoreConstants.NEW_PASSWORD_REQUIRED: {
      return {
        ...state,
        cognitoUser: action.payload,
        newPasswordRequired: true,
        isError: false,
        errorMessage: "",
      };
    }
    case StoreConstants.LOGOUT_SUCCESS: {
      return {
        ...state,
        cognitoUser: null,
        isError: false,
        errorMessage: "",
        session: null
      };
    }
    case StoreConstants.GET_FEATURE_TOGGLES_SUCCESS: {
      return {
        ...state,
        features: {
          ...action.payload,
          "13_faq": true,
        }
      };
    }
    case StoreConstants.LOGOUT_FAILED:
    case StoreConstants.REFRESH_CURRENT_SESSION_FAILED:
    case StoreConstants.LOGIN_FAILED:
      return {
        ...state,
        cognitoUser: null,
        session: null
      }
    case StoreConstants.GET_FEATURE_TOGGLES_FAILED:
    case StoreConstants.PUT_FEATURE_TOGGLES_SUCCESS:
    default:
      return state;
  }
};

export default userReducer;
